import React, { useState, useEffect } from 'react'; // Import useEffect
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import axios from 'axios';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';  
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTimes, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import logoImage2 from '../../img/logo2.png';
import logoImage1 from '../../img/systemLogo.png';
import logoBase64 from '../../img/imageData'; // Import the Base64 data for logo
import backgroundImage from '../../img/hol7.jpg';  // Import your background image here
import '../../styles/Report.css';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';  // Import xlsx library
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import loadingGif from '../../img/giphy.gif'; // Path to loading GIF

function BusRevenueReport() {
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState('');
  const [selectedRoute, setSelectedRoute] = useState('');
  const [selectedStatementConcept, setSelectedStatementConcept] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [tchStatus, setTchStatus] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState('');
  const [rows, setRows] = useState([]);
  const [tchStatuses, setTchStatuses] = useState([]);
  const [statementConcepts, setStatementConcepts] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // For logout navigation

  let idCounter = 0;
  const rowsWithIds = rows.map(row => ({ ...row, id: idCounter++ }));

  // Fetch data from API when component mounts
  useEffect(() => {
    axios.get('https://transit-api.thenakedscientists.co.za/api/Report/vehicle-revenue-report-filters')
      .then(response => {
        const { tchStatuses, statementConcepts, routes, merchants, vehicles } = response.data;
        setTchStatuses(tchStatuses);
        setStatementConcepts(statementConcepts);
        setRoutes(routes);
        setMerchants(merchants);
        setVehicles(vehicles);
      })
      .catch(error => {
        console.error('Error fetching dropdown data:', error);
      });
  }, []);

  // Columns for the DataGrid
  const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'route', headerName: 'Route', width: 150 },
    { field: 'merchantName', headerName: 'Merchant Name', width: 150 },
    { field: 'registration', headerName: 'Registration', width: 150 },
    { field: 'transactionQuantity', headerName: 'Transaction Quantity', width: 150 },
    { field: 'transactionTotalAmount', headerName: 'Transaction Total Amount', width: 150 },
  ];

  // Function to handle the form submission
  const handleQuery = () => {
    setLoading(true); // Show loading spinner
    const busRevenueReport = {
      merchantId: selectedMerchant,
      tchStatus: tchStatus.toString(),
      tchStatementConcept: selectedStatementConcept,
      transactionDateFrom: fromDate,
      transactionDateTo: toDate,
      routeId: selectedRoute,
      vehicles: selectedVehicles,
    };

    axios.post('https://transit-api.thenakedscientists.co.za/api/Report/vehicle-revenue-report', busRevenueReport, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        setRows(response.data);
        setLoading(false); // Hide loading spinner
      })
      .catch(error => {
        setLoading(false); // Hide loading spinner in case of an error
        console.error('Error fetching DataGrid data:', error);
      });
  };

  // Function to handle form reset
  const handleReset = () => {
    setSelectedMerchant('');
    setSelectedRoute('');
    setSelectedStatementConcept('');
    setFromDate('');
    setToDate('');
    setTchStatus('');
    setSelectedVehicles([]);
    setRows([]);
  };

  // Function to export to Excel
  const handleExportToExcel = () => {
    const now = new Date();
    const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Bus Revenue Report');
    XLSX.writeFile(workbook, `BusRevenueReport_${timestamp}.xlsx`);
  };

  // Function to export to PDF
  const handleExportToPDF = () => {
    const doc = new jsPDF('landscape');
    doc.addImage(logoBase64, 'PNG', 220, 15, 70, 36); // Logo
    doc.setFontSize(18);
    doc.text('Bus Revenue Report', 14, 22);
    doc.setFontSize(12);
    doc.text(`Generated on: ${new Date().toLocaleString()}`, 14, 30);

    const tableColumn = columns.map(col => col.headerName);
    const tableRows = rows.map(row => columns.map(col => row[col.field]));

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50,
      theme: 'striped',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [44, 62, 80] },
      alternateRowStyles: { fillColor: [245, 245, 245] },
    });

    const now = new Date();
    const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
    doc.save(`BusRevenueReport_${timestamp}.pdf`);
  };

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Clear user authentication data
    navigate('/login', { replace: true }); // Redirect to login and disable back navigation
  };

  return (
    <>
      {/* Header */}
      <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <i className="bi bi-list toggle-sidebar-btn" onClick={() => document.body.classList.toggle('toggle-sidebar')}></i>
            <a href=" " className="logo d-flex align-items-center">
              <img src={logoImage1} alt="Company Logo" className="logo-img" />
            </a>
          </div>
        </div>
      </header>

      {/* Sidebar */}
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <a className="nav-link" href="dashboard#">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <li className="nav-heading" style={{ fontWeight: "bold" }}>Reports</li>
          <li className="nav-item">
            <a className="nav-link" href="BusRevenueReport">
              <i className="bi bi-card-list"></i>
              <span>Bus Revenue Report</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="ConsumptionReport">
              <i className="bi bi-card-list"></i>
              <span>Consumption Report</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="MerchantRevenueReport">
              <i className="bi bi-card-list"></i>
              <span>Merchant Revenue Report</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="RouteRevenueReport">
              <i className="bi bi-card-list"></i>
              <span>Route Revenue Report</span>
            </a>
          </li>
          <li className="nav-heading" style={{ fontWeight: "bold" }}>Log Out</li>
          <li className="nav-item">
            <button className="nav-link" onClick={handleLogout}>
              <i className="bi bi-box-arrow-right"></i>
              <span>Log Out</span>
            </button>
          </li>
        </ul>
      </aside>

      {/* Main Content */}
      <main
        id="main"
        className="main"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          padding: '20px',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: -1,
          }}
        ></div>

        <div className="pagetitle">
          <h1>Bus Revenue Report</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="dashboard#">Home</a></li>
            </ol>
          </nav>
          <div style={{ textAlign: 'center' }}>
            <img src={logoImage2} alt="Dashboard Logo" style={{ width: '320px', height: '130px' }} />
          </div>
        </div>

        {loading && (
          <div className="loading-overlay">
            <img src={loadingGif} alt="Loading..." className="loading-gif" />
          </div>
        )}

        <Grid container spacing={2}>
          {/* Merchants Dropdown */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Select
                id="merchants"
                value={selectedMerchant}
                onChange={(e) => setSelectedMerchant(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Select Merchant</MenuItem>
                {merchants.map((merchant, index) => (
                  <MenuItem key={index} value={merchant.id}>{merchant.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Routes Dropdown */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Select
                id="routes"
                value={selectedRoute}
                onChange={(e) => setSelectedRoute(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Select Route</MenuItem>
                {routes.map(route => (
                  <MenuItem key={route.id} value={route.id}>{route.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Vehicles Dropdown */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Select
                id="vehicles"
                value={selectedVehicles}
                onChange={(e) => setSelectedVehicles(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Plate Number</MenuItem>
                {vehicles.map(vehicle => (
                  <MenuItem key={vehicle.id} value={vehicle.id}>{vehicle.registration}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Date Range Fields */}
          <Grid item xs={12} sm={6} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  id="fromDate"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="From Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="toDate"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="To Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Buttons */}
        <div className="row" style={{ marginBottom: '20px', marginTop: '20px' }}>
          <div className="col">
            <Button variant="contained" onClick={handleQuery} className="query-button">
              <FontAwesomeIcon icon={faArrowRight} /> Query
            </Button>
            <Button variant="contained" color="secondary" onClick={handleReset} className="reset-button">
              <FontAwesomeIcon icon={faTimes} /> Reset
            </Button>
          </div>
          <div className="col-auto">
            <Button variant="contained" className="export-button" color="success" onClick={handleExportToExcel}>
              <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '5px' }} /> Export to Excel
            </Button>
          </div>
          <div className="col-auto">
            <Button variant="contained" className="export-button" color="error" onClick={handleExportToPDF} style={{ marginLeft: '10px' }}>
              <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} /> Export to PDF
            </Button>
          </div>
        </div>

        {/* DataGrid */}
        <div style={{ height: 350, width: '100%' }}>
          <DataGrid
            rows={rowsWithIds}
            columns={columns.map((column) => ({ ...column, flex: 1 }))}
            pageSize={5}
            disableSelectionOnClick
            headerClassName="datagrid-header"
          />
        </div>
      </main>

      {/* Back to Top Button */}
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}

export default BusRevenueReport;
