import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Chart from 'chart.js/auto';
import logoImage2 from '../../img/logo2.png';
import logoImage1 from '../../img/systemLogo.png';
import '../../styles/Report.css';
import '../../img/systemLogo.png'; // Adjust the path as needed
import { Collapse } from 'react-bootstrap';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import '../../styles/bootstrap.min.css'; // Adjust the path as needed
import { logEvent } from '../../middleware/logger';
import backgroundImage from '../../img/hol7.jpg';  // Import your background image here


// Define the Dashboard component
const Dashboard = () => {
  // Refs for various charts and lists
  const salesChartRef = useRef(null);
  const revenueChartRef = useRef(null);
  const customersChartRef = useRef(null);
  const recentSalesChartRef = useRef(null);
  const topSellingChartRef = useRef(null);
  const recentActivityChartRef = useRef(null);
  const websiteTrafficChartRef = useRef(null);
  const topCardsRef = useRef(null);
  const onlineBusesRef = useRef(null);
  const offlineBusesRef = useRef(null);
  const navigate = useNavigate(); // For logout navigation
  

  // Sidebar toggle functionality
  useEffect(() => {
    const toggleButton = document.querySelector('.toggle-sidebar-btn');
    const sidebar = document.querySelector('.sidebar');
    const mainContent = document.querySelector('#main');
    const footer = document.querySelector('#footer');
    
    toggleButton.addEventListener('click', () => {
      sidebar.classList.toggle('toggle-sidebar');
      mainContent.classList.toggle('toggle-sidebar');
      if (footer) {
        footer.classList.toggle('toggle-sidebar');
      }
    });

    return () => {
      toggleButton.removeEventListener('click', () => {});
    };
  }, []);

  // useEffect to create and destroy charts
  useEffect(() => {
    // Sample data for Transactions Made Chart
    const salesData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June'],
      datasets: [
        {
          label: 'Transactions Made',
          data: [65, 59, 80, 81, 56, 55],
          fill: false,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
      ],
    };

    // Sample data for Revenue Chart
    const revenueData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June'],
      datasets: [
        {
          label: 'Revenue',
          data: [28, 48, 40, 19, 86, 27],
          fill: false,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    };

    // Sample data for Customers Chart
    const customersData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June'],
      datasets: [
        {
          label: 'Customers',
          data: [100, 120, 150, 180, 200, 220],
          fill: false,
          backgroundColor: 'rgba(255, 206, 86, 0.2)',
          borderColor: 'rgba(255, 206, 86, 1)',
          borderWidth: 1,
        },
      ],
    };

    // Sample data for Recent Sales Chart
    const recentSalesData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      datasets: [
        {
          label: 'Recent Trips',
          data: [35, 25, 45, 30, 55, 40],
          fill: false,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    // Sample data for Top Selling Products Chart
    const topSellingData = {
      labels: ['Bus 1', 'Bus 2', 'Bus 3', 'Bus 4', 'Bus 5'],
      datasets: [
        {
          label: 'Buses',
          data: [50, 45, 60, 55, 70],
          fill: false,
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
        },
      ],
    };

    // Create Charts using Chart.js
    const salesChart = new Chart(salesChartRef.current, {
      type: 'line',
      data: salesData,
    });

    const revenueChart = new Chart(revenueChartRef.current, {
      type: 'bar',
      data: revenueData,
    });

    const customersChart = new Chart(customersChartRef.current, {
      type: 'line',
      data: customersData,
    });

    const recentSalesChart = new Chart(recentSalesChartRef.current, {
      type: 'line',
      data: recentSalesData,
    });

    const topSellingChart = new Chart(topSellingChartRef.current, {
      type: 'bar',
      data: topSellingData,
    });

    // Clean up function to destroy charts
    return () => {
      salesChart.destroy();
      revenueChart.destroy();
      customersChart.destroy();
      recentSalesChart.destroy();
      topSellingChart.destroy();
    };
  }, []);

    // Sidebar toggle function
const toggleSidebar = () => {
  document.body.classList.toggle('toggle-sidebar');
};

 // Logout function
 const handleLogout = () => {
  localStorage.removeItem('authToken'); // Clear user authentication data
  navigate('/login', { replace: true }); // Redirect to login and disable back navigation
};

  // useEffect for creating and destroying additional charts
  useEffect(() => {
    // Sample data for Recent Activity Chart
    const recentActivityData = {
      labels: ['Bus 1', 'Bus 2', 'Bus 3', 'Bus 4', 'Bus 5'],
      datasets: [
        {
          label: 'Recent Activity',
          data: [20, 15, 25, 18, 22],
          fill: false,
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgba(255, 159, 64, 1)',
          borderWidth: 1,
        },
      ],
    };

    // Sample data for Website Traffic Chart
    const websiteTrafficData = {
      labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6'],
      datasets: [
        {
          label: 'Bus Traffic',
          data: [100, 120, 90, 110, 140, 130],
          fill: false,
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
        },
      ],
    };

    // Create additional charts
    const recentActivityChart = new Chart(recentActivityChartRef.current, {
      type: 'line',
      data: recentActivityData,
    });

    const websiteTrafficChart = new Chart(websiteTrafficChartRef.current, {
      type: 'line',
      data: websiteTrafficData,
    });

    // Clean up function to destroy additional charts
    return () => {
      recentActivityChart.destroy();
      websiteTrafficChart.destroy();
    };
  }, []);

  // Function to create lists
  const createList = (listRef, data) => {
    const list = document.createElement('ul');
    list.className = 'list-group';

    data.forEach(item => {
      const listItem = document.createElement('li');
      listItem.className = 'list-group-item';
      listItem.textContent = `${item.busName || item.cardNumber} - Usage: ${item.usageCount || 'N/A'}`;
      list.appendChild(listItem);
    });

    listRef.current.appendChild(list);
  };

  // Sample data for Top Cards List
  const topCardsData = [
    { id: 1, cardNumber: "1234 5678 9012", usageCount: 20 },
    { id: 2, cardNumber: "9876 5432 1098", usageCount: 18 },
    { id: 3, cardNumber: "2468 1357 9024", usageCount: 15 },
    { id: 4, cardNumber: "3698 7524 1087", usageCount: 12 },
    { id: 5, cardNumber: "8765 4321 9876", usageCount: 10 },
  ];

  // Sample data for Online Buses List
  const onlineBusesData = [
    { id: 1, busName: "Bus A", status: "Online" },
    { id: 2, busName: "Bus B", status: "Online" },
    { id: 3, busName: "Bus C", status: "Online" },
    { id: 4, busName: "Bus D", status: "Online" },
    { id: 5, busName: "Bus E", status: "Online" },
  ];

  // Sample data for Offline Buses List
  const offlineBusesData = [
    { id: 1, busName: "Bus F", status: "Offline" },
    { id: 2, busName: "Bus G", status: "Offline" },
    { id: 3, busName: "Bus H", status: "Offline" },
    { id: 4, busName: "Bus I", status: "Offline" },
    { id: 5, busName: "Bus J", status: "Offline" },
  ];

  // JSX to render the component
  return (
    <>
  {/* ======= Header ======= */}
  <header id="header" className="header fixed-top d-flex align-items-center">
    <div className="d-flex align-items-center justify-content-between w-100">
      {/* Sidebar toggle button and logo on the left side */}
      <div className="d-flex align-items-center">
        <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
        
        {/* Logo next to the sidebar toggle button */}
        <a href=" " className="logo d-flex align-items-center">
          <img src={logoImage1} alt="Company Logo" className="logo-img" />
        </a>
      </div>

      <div className="header-right-content">
        {/* Add any additional content here */}
      </div>
    </div>
  </header>


      {/* End Header */}
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <a className="nav-link" href="dashboard#">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <li className="nav-heading" style={{ fontWeight: "bold" }}>Reports</li>
          <li className="nav-item">
            <a className="nav-link" href="BusRevenueReport">
              <i className="bi bi-card-list"></i>
              <span>Bus Revenue Report</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="ConsumptionReport">
              <i className="bi bi-card-list"></i>
              <span>Consumption Report</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="MerchantRevenueReport">
              <i className="bi bi-card-list"></i>
              <span>Merchant Revenue Report</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="RouteRevenueReport">
              <i className="bi bi-card-list"></i>
              <span>Route Revenue Report</span>
            </a>
          </li>
          <li className="nav-heading" style={{ fontWeight: "bold" }}>Log Out</li>
          <li className="nav-item">
            <button className="nav-link" onClick={handleLogout}>
              <i className="bi bi-box-arrow-right"></i>
              <span>Log Out</span>
            </button>
          </li>
        </ul>
      </aside>
      {/* End Sidebar */}
    

      {/* Main Content */}
      <main
      id="main"
      className="main"
      style={{
        backgroundImage: `url(${backgroundImage})`, // Use the imported image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        padding: '20px',
        position: 'relative', // Set relative positioning to main
        zIndex: 1, // Ensure content stays above the background
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Add a slight transparent overlay behind content
          zIndex: -1, // Keep the overlay behind the content
        }}
      ></div>
        {/* Page Title */}
        <div className="pagetitle">
          <h1>Welcome to TNS PAY DASHBOARD</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="dashboard#">Home</a></li>
            </ol>
          </nav>
          <div style={{ textAlign: 'center' }}>
            <img src={logoImage2} alt="Dashboard Logo" style={{ width: '320px', height: '130px' }} />
          </div>
        </div>

        {/* Dashboard Section */}
        <section className="section dashboard">
          <div className="row">
            {/* Left side columns */}
            <div className="col-lg-8">
              <div className="row">
                {/* Sales Card */}
                <div className="col-xxl-4 col-md-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Transactions Made</h5>
                      <canvas ref={salesChartRef} width="200" height="200"></canvas>
                    </div>
                  </div>
                </div>
                {/* End Sales Card */}

                {/* Revenue Card */}
                <div className="col-xxl-4 col-md-6">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <h5 className="card-title">Revenue</h5>
                      <canvas ref={revenueChartRef} width="200" height="200"></canvas>
                    </div>
                  </div>
                </div>
                {/* End Revenue Card */}

                {/* Customers Card */}
                <div className="col-xxl-4 col-xl-12">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <h5 className="card-title">Customers</h5>
                      <canvas ref={customersChartRef} width="200" height="200"></canvas>
                    </div>
                  </div>
                </div>
                {/* End Customers Card */}

                {/* Recent Sales */}
                <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">Recent Trips</h5>
                      <canvas ref={recentSalesChartRef} width="50" height="11"></canvas>
                    </div>
                  </div>
                </div>
                {/* End Recent Sales */}

                {/* Top Selling */}
                <div className="col-12">
                  <div className="card top-selling overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">Most productive buses</h5>
                      <canvas ref={topSellingChartRef} width="50" height="11"></canvas>
                    </div>
                  </div>
                </div>
                {/* End Top Selling */}

                {/* Top Used Cards */}
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Top Used Cards</h5>
                      <div ref={topCardsRef}>
                        <ul className="list-group">
                          {topCardsData.map(card => (
                            <li key={card.id} className="list-group-item">
                              {card.cardNumber} - Usage: {card.usageCount}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Top Used Cards */}
              </div>
            </div>
            {/* End Left side columns */}

            {/* Right side columns */}
            <div className="col-lg-4">
              {/* Recent Activity */}
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Recent Activity</h5>
                  <canvas ref={recentActivityChartRef} width="50" height="15"></canvas>
                </div>
              </div>
              {/* End Recent Activity */}

              {/* Online Buses */}
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Online Buses</h5>
                  <ul className="list-group" ref={onlineBusesRef}>
                    {onlineBusesData.map(bus => (
                      <li key={bus.id} className="list-group-item">
                        {bus.busName} - Status: {bus.status}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* End Online Buses */}

              {/* Offline Buses */}
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Offline Buses</h5>
                  <ul className="list-group" ref={offlineBusesRef}>
                    {offlineBusesData.map(bus => (
                      <li key={bus.id} className="list-group-item">
                        {bus.busName} - Status: {bus.status}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* End Offline Buses */}
            </div>
            {/* End Right side columns */}
          </div>
        </section>
        {/* End Dashboard Section */}
      </main>
      {/* End Main Content */}

      {/* Back to Top Button */}
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};

// Export the Dashboard component
export default Dashboard;
